<template>
    <div>
        <div class="form-bg-box uf uf-ver uf-pc uf-ac">
            <div class="form-wrap">
                <div style="margin-top: -20px;margin-bottom: -10px;font-weight: bold;font-size: 18px;">
                  <el-page-header @back="goBack">
                    <div slot="content">
                      <span class="fwb gb-default-color">服务协同与动态适配</span>
                    </div>
                  </el-page-header>
                </div>
                <el-divider></el-divider>
                <div v-if="enterpriseList.length > 0">
                    <el-row :gutter="20">
                        <el-col :span="14">
                            <el-row :gutter="10"  style="margin-bottom: 10px">
                                <el-col :span="22">
                                    <el-input placeholder="请输入您想搜索的内容" v-model="searchContent">
                                    </el-input>
                                </el-col>
                                <el-col :span="2">
                                    <el-button type="primary" @click="getData">搜索</el-button>
                                </el-col>
                            </el-row>
                            <div v-for="(item, index) in serviceList" :key="index" name="index">
                                <el-card shadow="never" style="margin-bottom: 10px;height: 118px" >
                                    <el-row>
                                        <div>
                                            <span style="font-weight: bold;font-size: 20px">{{item.title}}</span>
                                            <span style="font-size: 16px;margin-left: 20px">{{item.labName}}</span>
                                            <span style="font-size: 16px;float: right;color: red" v-if="item.isFull">已满</span>
                                            <span style="font-size: 16px;float: right;color: #3db94d" v-else>未满</span>
                                        </div>
                                    </el-row>
                                    <el-row>
                                        <div style="margin-top: 5px;margin-bottom: 10px">
                                            <span>
                                                {{item.standard}}
                                            </span>
                                        </div>
                                    </el-row>
                                    <el-row>
                                        <div style="margin-top: 5px;margin-bottom: 10px">
                                            <span>
                                                联系电话/地址：{{item.phoneNumber}}
                                            </span>
                                            <span style="margin-left: 20px;font-size: 12px">
                                                {{item.address}}
                                            </span>
                                            <span style="float: right;color: #60b00e">
                                                距离:{{item.distance}}km
                                            </span>

                                            <span style="float: right;color: orange;">
                                                评分:{{item.score}}
                                            </span>
                                        </div>
                                    </el-row>
                                </el-card>
                            </div>
                            <el-pagination
                                    background
                                    @size-change="sizeChangeHandle"
                                    @current-change="currentChangeHandle"
                                    :current-page="pageIndex"
                                    :page-sizes="[2, 4, 6, 8]"
                                    :page-size="pageSize"
                                    :total="totalCount"
                                    layout="total, sizes, prev, pager, next, jumper">
                            </el-pagination>
                        </el-col>
                        <el-col :span="10">
                            <div id="map" class="map"></div>
                        </el-col>
                    </el-row>
                </div>
                <div style="width: 60%;margin: 0 auto;margin-right: 70px" v-else>
                   您还未绑定企业，立刻添加企业，获取服务。
                    <el-button @click="$router.push({name: 'enterprise'})" type="primary">
                        立即添加
                    </el-button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

    export default {
        name: 'recommend-result-cqc',
        components: {},
        computed: {},
        data() {
            return {
                loading: false,
                pageIndex: 1,
                pageSize: 4,
                totalCount: 0,
                totalPage: 0,
                enterpriseList: [],
                serviceList: [],
                labList: [],
                searchContent: ''
            }
        },
        mounted() {
            // this.getData()
        },
        created() {
            // this.getNearest()
            this.getEnterprise()
        },
        methods: {
            getEnterprise() {
                this.$http({
                    url: this.$http.adornUrl(this.$api.U.ENTERPRISE.LIST),
                    method: 'get',
                    params: this.$http.adornParams({
                        'page': this.pageIndex,
                        'limit': this.pageSize,
                        'enterpriseStatus': 1
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.enterpriseList = data.page.list
                        this.$nextTick(() => {
                            this.getData()
                        })
                    } else {
                        this.enterpriseList = []
                    }
                })
            },

            getData() {
                this.$http({
                    url: this.$http.adornUrl(this.$api.TECH.CQC.LIST),
                    method: 'POST',
                    params: this.$http.adornParams({
                        'page': this.pageIndex,
                        'limit': this.pageSize,
                        'q': this.searchContent || null
                    })
                }).then(({ data }) => {
                    if (data && data.code === 0) {
                        this.serviceList = data.serviceList
                        this.labList = data.labList
                        this.totalCount = this.serviceList.length
                        this.totalPage = this.serviceList.length/this.pageSize
                        var start = (this.pageIndex-1) * this.pageSize
                        var end = this.serviceList.length > this.pageIndex * this.pageSize ? this.pageIndex * this.pageSize : this.serviceList.length
                        this.serviceList = this.serviceList.slice(start, end)

                        // 百度地图API功能
                        var map = new BMapGL.Map('map') // 创建Map实例
                        map.centerAndZoom(new BMapGL.Point(105.404, 35.925), 5) // 初始化地图,设置中心点坐标和地图级别
//添加地图类型控件
                        map.addControl(
                            new BMapGL.MapTypeControl({
                                mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP],
                            })
                        )
                        // map.setCurrentCity('北京') // 设置地图显示的城市 此项是必须设置的
                        map.enableScrollWheelZoom(true)


                        this.labList.forEach(item => {
                                let point = new BMapGL.Point(item.location.split(',')[0], item.location.split(',')[1])
                                // 创建点标记
                                var marker = new BMapGL.Marker(point)
                                // 在地图上添加点标记
                                map.addOverlay(marker)
                                // 创建信息窗口
                                var opts = {
                                    width: 450,
                                    height: 100,
                                    title: item.labName,
                                }
                                var infoWindow = new BMapGL.InfoWindow(
                                    item.address,
                                    opts
                                )
                                // 点标记添加点击事件
                                marker.addEventListener('click', function () {
                                    map.openInfoWindow(infoWindow, point) // 开启信息窗口
                                })
                            }
                        )
                    }
                })

            },
            // 每页数
            sizeChangeHandle (val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getData(true)
            },
            // 当前页
            currentChangeHandle (val) {
                this.pageIndex = val
                this.getData(false)
            },
          goBack () {
              this.$router.go(-1)
          }

        },
        watch: {},
    }
</script>
<style scoped>
    a {
        color: #409eff;
    }

    .form-bg-box {
        width: 100%;
        min-height: calc(100vh - 150px);
        padding: 1vw;
        /*background: url("../../../../src/assets/img/recommend/hezuo.jpg") no-repeat;*/
        /*background: linear-gradient(30deg, #4bd8ff, #2afdff)*/
        /*background-position: center center;*/
        background-color: #F2F6FC;
        background-size: cover;
    }

    .form-wrap {
        width: 90vw;
        /*margin: 0 auto;*/
        padding: 2vw;
        background: rgba(255, 255, 255, .9);
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        border-radius: 14px;
        box-shadow: 0 0 36px rgba(2, 70, 172, .3);
    }

    .text-over {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    #recommend-result {
        height: 100vh;
        width: 98vw;
        padding: 20px;
        margin-top: 2vh;
        /*margin: 1vh auto 0 auto;*/
        background: #ffffff;
        border-radius: 20px;
        overflow: hidden;
        border-top: 1px solid #e8e8e8;
        position: relative;
    }

    .result-dialog >>> .el-dialog {
        margin: 0 auto;
    }

    #recommend-result .res-left {
        width: 40%;
        background: #FFFFFF;
    }

    #recommend-result .res-right {
        background: #FFFFFF;
    }

    #recommend-result .res-left .detail-info {
        padding: 10px;
        background: rgba(231, 243, 255, .6);
        font-size: 15px;
    }

    #recommend-result .res-left .detail-info > div {
        /*flex: 1;*/
        min-width: 200px;
        padding: 4px 8px;
        line-height: 26px;
    }

    .detail-info .address {
        width: 65%;
    }

    .labels-box {
        min-height: 140px;
    }

    .labels-box .l-wrap {
        padding-top: 30px;
    }

    .labels-box .label-li {
        position: relative;
    }

    .labels-box .label {
        width: 66px;
        height: 66px;
        font-weight: bold;
        line-height: 20px;
        padding: 16px;
        text-align: center;
        font-size: 14px;
        border-radius: 50%;
        color: #FFFFFF;
        animation: labels 4s linear infinite;
        position: absolute;
        /*top: 0;*/
        left: -30px;
    }

    .labels-box .label-li:nth-child(1) .label {
        background: linear-gradient(30deg, #3C8CE7, #00EAFF);
        box-shadow: 0 0 26px rgba(2, 70, 172, .3);
        transform: scale(1.2);
        animation-delay: 0s;
    }

    .labels-box .label-li:nth-child(2) .label {
        background: linear-gradient(30deg, #c509fd, #b28eff);
        box-shadow: 0 0 26px rgba(105, 0, 140, 0.3);
        transform: scale(.9);
        animation-delay: 3s;
    }

    .labels-box .label-li:nth-child(3) .label {
        background: linear-gradient(30deg, #69FF97, #00E4FF);
        box-shadow: 0 0 26px rgba(0, 138, 100, 0.3);
        transform: scale(1.1);
        animation-delay: 1s;
    }

    .labels-box .label-li:nth-child(4) .label {
        background: linear-gradient(30deg, #FFE985, #FA742B);
        box-shadow: 0 0 26px rgba(138, 27, 0, 0.3);
        transform: scale(.82);
        animation-delay: 1.8s;
    }

    .labels-box .label-li:nth-child(5) .label {
        background: linear-gradient(30deg, #00c3e3, #af9aff);
        box-shadow: 0 0 26px rgba(0, 108, 138, 0.3);
        transform: scale(1);
        animation-delay: 2.4s;
    }

    .tab-wrap {
        padding-bottom: 15px;

    }

    .tab-wrap .tab-box {
        height: 50%;
        width: 100px;
        border: 1px solid #e6e6e6;
        border-left: 0;
        /*background: #f7f7f7;*/
        /*background: linear-gradient(30deg, #3C8CE7 , #00EAFF);*/
        /*border-radius: 0 10px 10px 0;*/
        overflow: hidden;
    }

    .tab-wrap .tab-li {
        text-align: center;
        border-bottom: 1px solid #e6e6e6;
        padding: 0 8px;
        cursor: pointer;
        transition: all .3s ease;
        background: #f2f4f9;
    }

    .tab-wrap .tab-li:hover {
        background: #b8babe;
    }

    .tab-wrap .tab-li.on, .tab-wrap .tab-li.on:hover {
        color: #FFFFFF;
        background: linear-gradient(30deg, #3C8CE7, #00EAFF);
    }

    .tab-wrap .tab-li:last-child {
        border: none;
    }

    .tab-wrap .tab-li .iconfont {
        font-size: 30px;
    }

    .tab-wrap .tab-li .tab-name {
        font-size: 15px;
        font-weight: bold;
        margin-top: 10px;
    }

    .tab-content {
        padding: 0 10px 0 10px;
    }

    .list-box .list {
        width: auto;
        min-width: 220px;
        flex: 1;
    }

    .list-box .list.hidden {
        height: 1px;
        margin: 0 10px;
    }

    @keyframes labels {
        0% {
            top: 0;
        }
        25% {
            top: -14px;
        }
        50% {
            top: 0;
        }
        75% {
            top: 14px;
        }
        100% {
            top: 0;
        }
    }

    .zxw-nav {
        /*background: linear-gradient(90deg, #35bce2, #409eff);*/
        background: white;
        line-height: 2.4rem;
        width: 100%;
        outline: none;
    }

    .zxw-nav .wrap {
        width: 1300px;
        margin: 0 auto;
    }

    .zxw-nav li {
        width: 7rem;
        color: #404040;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
    }

    .zxw-nav li:hover,
    .zxw-nav li.on {
        background: #69c2ff;
        font-size: 15px;
        color: white;
    }

    .pop-aside-wrap {
        height: 415px;
        /*padding-right: 50px;*/
        position: relative;
        overflow: hidden;
        /*line-height: 32px;*/
        transition: all .3s;
    }

    .pop-aside-wrap-expand {
        height: calc(100vh - 190px - 24px);
        /*overflow-y: auto;*/
        padding-bottom: 24px;
    }

    .el-popper[x-placement^="right"] {
        margin-left: 0;
        border-radius: 0;
        padding-left: 20px;
    }

    .menu-box .tit {
        color: white;
        width: 200px;
        line-height: 2.4rem;
        padding-left: 1.4rem;
        background: #409eff;
        border-radius: 10px 10px 0 0;
        /*margin-top: -2.4rem;*/
        cursor: pointer;
    }

    .menu-box {
        position: absolute;
        top: 50px;
        z-index: 1999;
        background: rgba(255, 255, 255, .95);
        box-shadow: 0 0 28px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
    }

    .menu-box .menu {
        width: 200px;
        height: 408px;
        opacity: 1;
        transform: scale(1, 1);
        transform-origin: top left;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }

    .menu-box .menu.hide {
        height: 0;
        width: 0;
        transform: scale(0, 0);
        opacity: 0;
    }

    .menu-box .menu > div {
        /*min-height: 452px;*/
    }

    .menu .tab .iconfont {
        font-size: 20px;
        width: 26px;
        margin-right: 6px;
        font-weight: normal;
    }

    .menu .tab-box {
        padding-top: 10px;
        padding-left: 0;
    }

    .menu .tab-box .tab {
        height: 48px;
        cursor: pointer;
        font-size: 16px;
        color: #6d6d6d;
        width: 100%;
        padding: 0 5px 0 13px;
        position: relative;
    }

    .menu .tab-box .tab:hover,
    .menu .tab-box .tab.on {
        /*color: #409eff;*/
        background: #ffffff;
    }

    .menu .tab-box .tab:hover:before,
    .menu .tab-box .tab.on:before {
        /*color: #409eff;*/
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 42px;
        width: 100%;
        box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
        /*background: #ffffff;*/
        z-index: -1;
    }

    .menu-pop {
        height: 452px;
        position: absolute;
        left: 199px;
        top: 0;
        width: 200px;
        background: #fff;
        /*min-width: 200px !important;*/
        /*max-width: 750px !important;*/
        border-radius: 4px;
        border: 1px solid #ebeef5;
        padding: 12px;
        z-index: -1;
        color: #606266;
        line-height: 1.4;
        text-align: justify;
        font-size: 14px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        word-break: break-all;
        transition: width .3s ease-in-out;
    }

    .menu-pop.large-type {
        width: 880px;
        transition: width .3s ease-in-out;

    }

    .menu-pop-expand {
        height: calc(100vh - 190px);
        padding: 12px 12px 42px 12px;
    }

    .box-shadow {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .pop-aside-wrap .pop-title {
        font-size: 15px;
        font-weight: bold;
        color: #333333;
        cursor: pointer;
    }

    .pop-aside-wrap .pop-title:hover {
        color: #409eff;
    }

    .pop-aside-wrap .second-list {
        cursor: pointer;

    }

    .pop-aside-wrap .second-wrap {
        /*flex-wrap: nowrap;*/
    }

    .pop-aside-wrap .second-list:hover {
        color: #409eff;
    }

    .menu .icon-jiantou-you {
        font-size: 12px !important;
    }

    /*服务贴边栏样式*/
    .menu-box.fixed-left {
        position: fixed;
        left: 40px;
        top: 140px;
        border-radius: 0 10px 10px 10px;
    }

    .menu-box.fixed-left .el-icon-menu {
        margin-right: 6px;
        margin-bottom: 6px;
        margin-top: 10px;
    }

    .menu-box.fixed-left .tit {
        position: absolute;
        left: -40px;
        width: 40px;
        height: 200px;
        padding: 8px;
        flex-direction: column;
        line-height: 1.2rem;
        border-radius: 0;
    }

    .expand-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 42px;
        line-height: 42px;
        background: #fff;
        opacity: 0.9;
        text-align: center;
        cursor: pointer;
        color: #409eff;
    }

    .expand-btn:hover {
        opacity: 0.8;
    }

    .suspension {
        position: fixed;
        width: 75px;
        height: 75px;
        border-radius: 50%;
        top: 140px;
        right: 100px;
        z-index: 2000;
        cursor: pointer;
        text-align: center;
        line-height: 75px;
        font-size: 16px;
        color: #409eff;
        background: rgba(0, 0, 0, 0.2);
    }

    /*需求弹框覆盖样式*/
    .el-dialog /deep/ .el-dialog__footer {
        padding-top: 0;
    }

    .el-dialog /deep/ .el-dialog__header {
        padding-bottom: 0;
    }

    .el-form /deep/ .el-button {
        width: 150px;
        transform: translateX(-40px);
    }

    .icon-svg-menu {
        width: 24px;
        margin-right: 5px;
        text-align: center;
        font-size: 16px;
        color: inherit !important;
        transition: inline-block .3s, left .3s, width .3s, margin-left .3s, font-size .3s;
    }

    .zxw-nav >>> .el-scrollbar__wrap {
        overflow-x: hidden !important;
    }

    .banner2 {
        height: 44vh;
    }

    .banner2 .wrap {
        width: 80%;
        margin: 0 auto;
        height: 100%;
    }

    .container {
        width: 80%;
        background: white;
        margin: -30px auto 0 auto;
        border-radius: 14px;
        min-height: 40vh;

        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        padding: 15px;
    }

    .nav-item {
        margin: 0 20px;
    }

    .nav-item > .iconfont {
        font-size: 20px;
        margin-right: 10px;
    }

    .form-box {

        transform: translateX(-50%);
        background: rgba(255, 255, 255, .4);
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);
        box-shadow: 0 0 30px rgba(0, 0, 0, .1);
        border-radius: 14px;
        margin: 0 auto;
        padding: 20px 30px;
    }

    /*.form-box {*/
    /*  max-width: 600px;*/
    /*  padding-top: 20px;*/
    /*  margin: 0 auto;*/
    /*}*/
    @media only screen and (max-width: 1600px) {
        .detail-info .address {
            width: 100%;
        }

        .tab-wrap .tab-li .tab-name {
            font-size: 14px;
            margin-top: 6px;
        }
    }

    /* Dialog对话框 公共部分样式 Start*/
    /*蒙层部分*/
    .commonDialog {
        width: 100%;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.6);
        z-index: 101;
        overflow: hidden;
    }

    /*框体部分*/
    .commonDialog .content {
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 9px;
        box-shadow: 2px 3px 5px #bbb;
        overflow: hidden;
        padding-bottom: 25px;
        margin-bottom: 30px;
        max-height: 90%;
        width: 1000px;
    }

    /*head 部分*/
    .commonDialog .content .head {
        height: 44px;
        line-height: 44px;
        background: #efefef;
        width: 100%;
        text-align: center;
        color: #ff490b;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        box-sizing: border-box;
    }

    /*内容区部分*/
    .commonDialog .content .main {
        width: 100%;
        max-height: 70vh;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 25px 20px;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        margin-bottom: 5px;
    }

    .el-dialog, .el-message-box {
        border-radius: 9px;
    }

    .el-dialog__headerbtn, .el-message-box__headerbtn {
        /* top: 5px !important;
         right: 10px;*/
        font-size: 18px;
    }

    .el-icon-close {
        color: #666 !important;
    }

    .el-dialog__header, .el-message-box__header {
        margin: 1rem;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        justify-content: space-between;
        align-items: center;

    }

    .el-dialog__title, .el-message-box__title {
        margin: 1rem;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #ff9123;
    }

    .coverage-release .el-dialog__body {
        padding: 20px;
    }

    /* Dialog对话框 公共部分样式 End*/
    .steps > div {
        margin-bottom: 8px;
        color: #03d39e;
    }

    .steps > div.doing {
        color: #292929;
    }

    .steps > div.fail {
        color: #ff5b00;
    }

    .steps i {
        margin-right: 10px;
    }

    .text-shenglue {
        width: 12vw;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
    }

    .newVec {
        cursor: pointer;
        font-weight: bold;
        color: #409EFF;
    }

    /*.newVec:hover {*/
    /*    font-size: 18px;*/
    /*}*/
    .area {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 20px;
        margin-left: 15px;
        color: white;
    }
    .area1 {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 10px;
        margin-left: 15px;
        color: white;
        margin-top: -5px;
    }
    .map {
        height: 550px;
        width: 680px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
</style>
